<template>
  <a-layout-content>
    <a-alert v-if="hasReason" type="warning" :message="`理由: ${reason}`" />
    <a-page-header
      title="采购单详情"
      :avatar="{ src: avatar }"
      @back="$router.back()"
    >
      <template #tags>
        <a-tag :color="auditStatus | color('audit')">
          {{ auditStatus | status('audit') }}
        </a-tag>
        <a-tag :color="assignStatus | color('assign')">
          {{ assignStatus | status('assign') }}
          <span v-if="hasAssigner">
            {{ `, 由${assigner}执行` }}
          </span>
        </a-tag>
        <a-tag :color="billStatus | color('bill')">
          {{ billStatus | status('bill') }}
        </a-tag>
        <a-tag v-if="timeout" color="red ">
          {{ timeout | boolean }}
        </a-tag>
      </template>
      <template #extra>
        <a-statistic title="RP编号" :value="code" />
      </template>
    </a-page-header>
    <a-skeleton :loading="loading">
      <a-space direction="vertical" size="large">
        <a-descriptions
          v-for="(item, index) in details"
          :key="index"
          :title="item.title"
          size="small"
          bordered
          :column="2"
        >
          <a-descriptions-item
            v-for="(it, j) in item.metas"
            :key="j"
            :label="it.label"
          >
            {{ it.value }}
          </a-descriptions-item>
        </a-descriptions>
        <app-upload disabled :value="files" />
        <app-steps :current="currentStep" :steps="steps" disabled />
        <app-actions
          :id="id"
          :type="type"
          :audit-status="auditStatus"
          :assign-status="assignStatus"
          :bill-status="billStatus"
        />
      </a-space>
    </a-skeleton>
  </a-layout-content>
</template>

<script>
import descriptors from '@/descriptors';

export default {
  name: 'Detail',
  props: {
    id: String,
    type: String
  },
  data() {
    return {
      code: undefined,
      avatar: undefined,
      auditStatus: undefined,
      assignStatus: undefined,
      assigner: undefined,
      billStatus: undefined,
      timeout: undefined,
      details: [],
      files: undefined,
      currentStep: undefined,
      steps: undefined,
      reason: undefined,
      loading: false
    };
  },
  computed: {
    hasReason() {
      const { reason } = this;
      return reason && reason != '';
    },
    hasAssigner() {
      return this.assigner != null;
    }
  },
  async created() {
    await this.getItem();
  },
  methods: {
    async getItem() {
      try {
        this.loading = true;
        // 元数据
        const {
          titleItem,
          applyItem,
          basicItem,
          contentItem,
          totalItem,
          descriptionItem,
          stepsItem
        } = descriptors;
        const data = await this.$http.get('/orders/detail', {
          params: { id: this.id }
        });
        // 单号
        this.code = data.code;
        // 头像
        this.avatar = data.avatar;
        // 状态
        this.auditStatus = data.auditStatus;
        this.assignStatus = data.assignStatus;
        this.assigner = data.assigner;
        this.billStatus = data.billStatus;
        // 超时
        this.timeout = data.timeout;
        // 标题
        const title = titleItem();
        title.metas[0].value = data.title;
        title.metas[1].value = data.subcompary;
        // 单项，应用，基本信息，合计，描述
        const apply = applyItem();
        const basic = basicItem();
        const total = totalItem();
        const description = descriptionItem();
        total.metas[0].value = data.total;
        description.metas = description.metas.filter(item => !item.hidden);
        this.setValues(apply, data.apply[0]);
        this.setValues(basic, data.basic[0]);
        this.setValues(description, data.description[0]);
        // 多项，内容
        const contents = [];
        data.content.forEach(item => {
          const content = contentItem();
          this.setValues(content, item);
          contents.push(content);
        });
        // 合并
        this.details.push(title, apply, basic, ...contents, total, description);
        // 文件
        this.files = data.description[0].files;
        // 步骤
        this.steps = stepsItem();
        this.currentStep = data.steps.currentStep;
        this.steps.forEach((item, index) => {
          const users = data.steps[`step${index + 1}`];
          const length = users?.length;
          const detail = length ? `${length}人审批` : '0人审批';
          item.detail = detail;
          item.status = this.currentStep > index ? 'finish' : 'process';
          item.users = users;
        });
        // 回退理由
        this.reason = data.reason;
      } finally {
        this.loading = false;
      }
    },
    setValues(obj, data) {
      obj.metas.forEach(item => {
        item.value = data[item.prop];
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ant-layout-content {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  background-color: #fff;

  /deep/ .ant-descriptions {
    table {
      table-layout: fixed;
    }
  }
}
</style>
